/** @jsxImportSource @emotion/react */
import { ContactBackground, GridLayout, TextContainer, styles } from './styles';
import StyledText from '../../atoms/StyledText';
import { ListItem } from '../../atoms/ListItem';
import { texValues } from '../../dimensions/dimensions';
import { css } from '@emotion/react';

const Contact = () => {
  return (
    <ContactBackground id='contacto'>
      <GridLayout
        css={css`
          @media (max-width: 920px) {
            grid-template-columns: 1fr;
          }
        `}
      >
        <TextContainer>
          <StyledText
            fontSize={texValues.subtitle}
            text='Contacto'
            customStyles={styles}
          />
          <StyledText
            fontSize={texValues.subtitleSection}
            text='Nùmeros de teléfono '
          />
          <ul>
            <ListItem>773 736 0428</ListItem>
            <ListItem>773 137 9443</ListItem>
          </ul>
          <StyledText
            fontSize={texValues.subtitleSection}
            text='Correos electrónicos '
          />
          <ul
            css={css`
              @media (max-width: 420px) {
                padding: 0;
              }
            `}
          >
            <ListItem>administracion@sanitariosvilladonga.com</ListItem>
            <ListItem>contacto@sanitariosvilladonga.com</ListItem>
          </ul>
        </TextContainer>
      </GridLayout>
    </ContactBackground>
  );
};

export default Contact;
