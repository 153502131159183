import React from 'react';
import HomePoster from '../../molecules/HomePoster';
import Services from '../../molecules/Services';
import Contact from '../../molecules/Contact';
import NavBar from '../../molecules/NavBar';

const Home = () => {
  return (
    <div>
      <NavBar />
      <HomePoster />
      <Services />
      <Contact />
      <iframe
        id='ubicacion'
        title='Ubicación Servicio Villadonga '
        src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14991.70696725492!2d-99.314716!3d20.053511!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb3cf864ad16c1d26!2sServicio+Villadonga+%7C+Sanitarios!5e0!3m2!1ses-419!2smx!4v1507260404774'
        width='100%'
        height={550}
        frameborder='0'
        allowfullscreen
      ></iframe>
    </div>
  );
};

export default Home;
