/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { texValues } from '../../dimensions/dimensions';

export const NavBarContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 8fr 1fr',
  position: 'fixed',
  height: 60,
  backgroundColor: '#ecf2f5',
  width: '100%',
  top: 0,
  zIndex: 10,
});

export const NavItemsContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const NavBarItems = styled.a({
  fontSize: texValues.navBar,
  margin: '0 20px',
  color: '#02557f',
  '&:hover': {
    textDecoration: 'none',
  },
});
