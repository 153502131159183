/** @jsxImportSource @emotion/react */

import React from 'react';
import { Text } from './styles';

const StyledText = ({ text, fontSize, customStyles }) => {
  return <Text css={[customStyles, { fontSize: fontSize }]}>{text}</Text>;
};

export default StyledText;
