/** @jsxImportSource @emotion/react */
import Duty from '../../../images/onDuty.jpeg';
import Clean from '../../../images/sanitizer.jpeg';
import customTruck from '../../../images/customTruck.jpeg';
import unidad from '../../../images/detail.jpeg';

import styled from '@emotion/styled';

const sharedStyles = {
  width: '100%',
  height: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: 15,
  backgroundPosition: 'center',
};

export const Servicios = styled.div({
  width: '100%',
  height: 550,
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '1fr',
});

export const ServicesTextcontainer = styled.div({
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ImageContainer = styled.div({
  display: 'grid',
  padding: 40,
  gridGap: 20,
  gridTemplateColumns: ' 1fr  1fr',
  gridTemplateRows: ' 1fr  1fr ',
});

export const APicFrame = styled.div({
  backgroundImage: `url(${Duty})`,
  ...sharedStyles,
});

export const BPicFrame = styled.div({
  backgroundImage: `url(${customTruck})`,
  ...sharedStyles,
});

export const CPicFrame = styled.div({
  backgroundImage: `url(${unidad})`,
  ...sharedStyles,
});

export const DPicFrame = styled.div({
  backgroundImage: `url(${Clean})`,
  ...sharedStyles,
});

export const styles = {
  color: '#02557f',
};
