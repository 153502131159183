/** @jsxImportSource @emotion/react */
import StyledText from '../../atoms/StyledText';
import { BackgroundImage, Cover, BackDrop, styles } from './styles';
import { texValues } from '../../dimensions/dimensions';

const HomePoster = () => {
  return (
    <Cover id='inicio'>
      <StyledText
        fontSize={texValues.title}
        text='Sanitarios Villadonga'
        customStyles={styles}
      />
      <BackDrop />
      <BackgroundImage />
    </Cover>
  );
};

export default HomePoster;
