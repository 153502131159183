/** @jsxImportSource @emotion/react */
import Card from '../../../images/tarjetasback.jpg';

import styled from '@emotion/styled';

export const ContactBackground = styled.div({
  width: '100%',
  height: 550,
  backgroundImage: `url(${Card})`,
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  backgroundSize: 'cover',
});

export const GridLayout = styled.div({
  display: 'grid',
  width: '100%',
  height: '100%',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '1fr',
});

export const TextContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

export const styles = {
  color: 'white',
};
