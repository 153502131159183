/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';

export const Text = styled.p({
  margin: 0,
  width: 'max-content',
  fontWeight: 700,
  textTransform: 'uppercase',
  letterSpacing: 2.2,
});
