/** @jsxImportSource @emotion/react */
import StyledText from '../../atoms/StyledText';
import { ListItem } from '../../atoms/ListItem';
import { texValues } from '../../dimensions/dimensions';

import { css } from '@emotion/react';

import {
  Servicios,
  ImageContainer,
  ServicesTextcontainer,
  APicFrame,
  BPicFrame,
  CPicFrame,
  DPicFrame,
  styles,
} from './styles';

const Services = () => {
  return (
    <Servicios
      id='servicios'
      css={css`
        @media (max-width: 920px) {
          grid-template-columns: 1fr;
          grid-template-rows: 1fr 2fr;
        }
      `}
    >
      <ServicesTextcontainer
        css={css`
          @media (max-width: 920px) {
            padding-top: 50px;
          }
        `}
      >
        <StyledText
          fontSize={texValues.subtitle}
          text='Servicios'
          customStyles={styles}
        />
        <ul>
          <ListItem>Sanitarios y Sanitarios VIP</ListItem>
          <ListItem>Lavamanos</ListItem>
          <ListItem>Fosas sépticas</ListItem>
          <ListItem>Sondeo y limpieza de drenajes</ListItem>
          <ListItem>
            Desinfección de calles con vactor y <br /> casas o lugares cerrados
            con termonebulizador
          </ListItem>
          <ListItem>Remolque</ListItem>
        </ul>
      </ServicesTextcontainer>
      <ImageContainer>
        <APicFrame />
        <BPicFrame />
        <CPicFrame />
        <DPicFrame />
      </ImageContainer>
    </Servicios>
  );
};

export default Services;
