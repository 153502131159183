/** @jsxImportSource @emotion/react */
import { Logo } from '../../atoms/Logo';
import { NavBarContainer, NavBarItems, NavItemsContainer } from './styles';
import { css } from '@emotion/react';

const sharedMediaRule = css`
  @media (max-width: 920px) {
    display: none;
  }
`;

const NavBar = () => {
  return (
    <NavBarContainer
      css={css`
        @media (max-width: 920px) {
          grid-template-columns: 1fr;
        }
      `}
    >
      <Logo
        width='75%'
        style={{ justifySelf: 'center', alignSelf: 'center' }}
        css={css`
          @media (max-width: 920px) {
            width: 130px;
          }
        `}
      />
      <NavItemsContainer css={sharedMediaRule}>
        <NavBarItems href='#inicio' css={sharedMediaRule}>
          Inicio
        </NavBarItems>
        <NavBarItems href='#servicios' css={sharedMediaRule}>
          Servicios
        </NavBarItems>
        <NavBarItems href='#contacto' css={sharedMediaRule}>
          Contacto
        </NavBarItems>
        <NavBarItems href='#ubicacion' css={sharedMediaRule}>
          Ubicación
        </NavBarItems>
      </NavItemsContainer>
      <div></div>
    </NavBarContainer>
  );
};

export default NavBar;
