/** @jsxImportSource @emotion/react */
import Poster from '../../../images/poster.jpeg';

import styled from '@emotion/styled';

const sharedStyles = {
  width: '100%',
  height: '100%',
};

export const Cover = styled.div({
  width: '100%',
  height: '75vh',
  position: 'relative',
});

export const BackgroundImage = styled.img({
  backgroundImage: `url(${Poster})`,
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  backgroundSize: 'cover',
  backgroundPosition: 'right',
  backgroundPositionY: -120,
  ...sharedStyles,
});

export const BackDrop = styled.div({
  backgroundColor: 'black',
  position: 'absolute',
  mixBlendMode: 'multiply',
  filter: 'opacity(0.5)',
  ...sharedStyles,
});

export const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'white',
  zIndex: 1,
};
